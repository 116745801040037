<template>
  <div
    id="row"
  >
    <p
    id="label"
    >
      <slot />
    </p>
    <div>
      <p
        id="searches"
      >
        {{ messages.getLabelRecentSearches() }}
      </p>
      <div>
        <b-badge pill
          v-for="(searchEntry, index) in searchHistory"
          :key=index
          @click="searchFromHistory(searchEntry)"
        >{{ searchEntry.searchText }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'SearchPageBody',
  props: {
    searchHistory: {
      type: Array,
      default: () => []
    }
  },
  created () {
    this.$emit('set-background-color', '#F4F4F4')
  },
  methods: {
    searchFromHistory (searchValue) {
      this.$emit('search-from-history', searchValue)
    }
  },
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped lang="scss">
#row {
  padding: 26px 16px 0 16px;
}
#label {
  color: #5F6468;
  margin-bottom: 32px;
}
#searches {
  font-size: 18px;
  color: #707070;
}
.badge {
  background-color: white;
  color: $primary;
  font-size: 16px;
  padding: .6em 1.2em .6em 1.2em;
  font-weight: normal;
  margin: 5px;
}
</style>
