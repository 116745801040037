<template>
  <base-top-bar>
    <template
      v-slot:left
    >
      <back-button />
    </template>
     <search-box-multi
        class="multi-search-box"
        v-model="searchInput"
        :hint-text="''"
        @input="submitSearch"
        :search-suggestion="searchSuggestionsList"
        :error-indexes="errorIndexes"
        :allow-add-before-search="allowAddBeforeSearch"
      />
    <template
      v-slot:right
    >
      <!--
        TODO: @anyone: If functionality is added to this `<b-nav-item>`, add an
        `aria-label` and a `title` (with the same text) to its `<icon-base>`.
      -->
      <b-nav-item>
        <icon-base
          width="18px"
          height="18px"
          icon-color="white"
        >
          <icon-qr-scan />
        </icon-base>
      </b-nav-item>
    </template>
  </base-top-bar>
</template>

<script>
import BaseTopBar from '../navigation/BaseTopBar'
import IconBase from '../icons/IconBase'
import IconQrScan from '../icons/IconQrScan'
import BackButton from '../navigation/BackButton'
import SearchBoxMulti from '@/components/FilterPanel/SearchBoxMulti'

export default {
  name: 'SearchTopBar',
  components: { BackButton, IconQrScan, IconBase, BaseTopBar, SearchBoxMulti },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    searchSuggestionsList: {
      type: Array,
      default: () => []
    },
    errorIndexes: {
      type: Array,
      default: () => []
    },
    allowAddBeforeSearch: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searchInput: []
    }
  },
  methods: {
    submitSearch (value, performSearch) {
      this.$emit('submit', this.searchInput, performSearch)
    }
  }
}
</script>

<style scoped>
.search-box {
  background-color: white;
  height: 36px;
  color: initial;
}
.input-container {
  padding: 0;
  width: 100%;
  border: none;
  display: flex;
}
.input {
  border: none;
  font-size: 18px;
  width: 100%;
}
.input:focus {
  outline: none;
}
.side-icon {
  padding: 0 10px 0 10px;
  border: none;
  display: flex;
  align-items: center;
}
</style>
