<template>
  <path d="M14,29H6V13.8C6,9.499,9.499,6,13.8,6H29v8H14V29z M14,67H6v15.2c0,4.301,3.499,7.8,7.8,7.8H29v-8H14V67z M82,29h8V13.8C90,9.499,86.501,6,82.2,6H67v8h15V29z M0,44v8h96v-8H0z M82,82H67v8h15.2c4.301,0,7.8-3.499,7.8-7.8V67h-8V82z"/>
</template>

<script>
export default {
  name: 'IconQrScan'
}
</script>

<style scoped>

</style>
